import { Filter, FilterDefinition, FiltersObject } from '@/lib/types/filters';
import { SlugItem } from '@/lib/types/models/common';
import { Strapi4RequestParams } from '@/lib/types/strapi';

interface FilterObjectPayload {
  destinations: Filter<SlugItem<string>>[];
  wishCategories: FilterDefinition<'wishCategories'>[] | null;
  typeCategories: FilterDefinition<'typeCategories'>[] | null;
  typeAndWishCategories: FilterDefinition<'typeAndWishCategories'>[] | null;
  seasons: FilterDefinition<'seasons'>[] | null;
  budgets: FilterDefinition<'budgets'>[] | null;
  durations: FilterDefinition<'durations'>[] | null;
  bestSeller: Filter<boolean> | null;
  articleTags: FilterDefinition<'articleTags'>[] | null;
  wpContinents: FilterDefinition<'wpContinents'>[] | null;
  eventTags: FilterDefinition<'eventTags'>[] | null;
  months: FilterDefinition<'months'>[] | null;
  stopoverTownCategories: FilterDefinition<'stopoverTownCategories'>[] | null;
  codes: FilterDefinition<'codes'>[] | null;
}

interface Query extends Record<string, unknown> {
  $and: Array<
    | { categories: object }
    | {
        categories: {
          parent: object;
        };
      }
    | {
        $or: Array<unknown>;
      }
  >;
  id?: string[];
  label?: string;
}

export default function computeStrapiFilter(
  filtersObject: FiltersObject,
  resultType: 'journey' | 'article' | 'event' = 'journey'
) {
  const payloads: FilterObjectPayload = {
    destinations: (filtersObject.destinations as Filter<SlugItem>[]) ?? [],
    wishCategories: filtersObject.wishCategories as FilterDefinition<'wishCategories'>[] | null,
    typeCategories: filtersObject.typeCategories as FilterDefinition<'typeCategories'>[] | null,
    typeAndWishCategories: filtersObject.typeAndWishCategories as
      | FilterDefinition<'typeAndWishCategories'>[]
      | null,
    stopoverTownCategories: filtersObject.stopoverTownCategories as
      | FilterDefinition<'stopoverTownCategories'>[]
      | null,
    seasons: filtersObject.seasons as FilterDefinition<'seasons'>[] | null,
    budgets: filtersObject.budgets as FilterDefinition<'budgets'>[] | null,
    durations: filtersObject.durations as FilterDefinition<'durations'>[] | null,
    bestSeller: filtersObject.bestSeller as Filter<boolean> | null,
    articleTags: filtersObject.articleTags as FilterDefinition<'articleTags'>[] | null,
    eventTags: filtersObject.eventTags as FilterDefinition<'eventTags'>[] | null,
    months: filtersObject.months as FilterDefinition<'months'>[] | null,
    wpContinents: filtersObject.wpContinents as FilterDefinition<'wpContinents'>[] | null,

    codes: filtersObject.codes as FilterDefinition<'codes'>[] | null
  };

  if (resultType === 'journey') {
    return getJourneyFilter(payloads);
  }

  if (resultType === 'article') {
    return getArticleFilter(payloads);
  }

  if (resultType === 'event') {
    return getEventFilter(payloads);
  }

  return {};
}

function getCategoryFilter(destinationSlug: SlugItem) {
  if (!destinationSlug.type) {
    return {};
  }

  const categoryAttributes = {
    country: {
      categoryMdvAttribute: 'country',
      mdvDstMapsShortname: destinationSlug.code
    },
    continent: {
      categoryMdvAttribute: 'geographic-zone',
      urlKey: destinationSlug.slug
    },
    countryBySlug: {
      categoryMdvAttribute: 'country',
      urlKey: destinationSlug.slug
    },
    'stopover-town': {
      categoryMdvAttribute: 'stopover-town',
      urlKey: destinationSlug.slug
    }
  };

  return categoryAttributes[destinationSlug.type];
}

function getJourneyFilter(payloads: FilterObjectPayload): Strapi4RequestParams['filters'] {
  const filter: Query = { $and: [] };

  filter.$and = [
    createCategoryFilterObject(payloads.seasons ?? []),
    createCategoryFilterObject(payloads.typeCategories ?? []),
    createCategoryFilterObject(payloads.typeAndWishCategories ?? []),
    createCategoryFilterObject(payloads.wishCategories ?? []),
    createCategoryFilterObject(payloads.stopoverTownCategories ?? []),
    {
      categories: {
        $or: payloads.destinations.map(item => getCategoryFilter(item.value))
      }
    }
  ];

  if (payloads.budgets?.length && payloads.budgets.length > 0) {
    filter.$and.push(filterByBudget(payloads.budgets));
  }

  if (payloads.durations && payloads.durations?.length > 0) {
    filter.$and.push(filterByDuration(payloads.durations));
  }

  if (payloads.bestSeller?.value) {
    filter.label = 'best_seller';
  }

  if (payloads.codes?.length && payloads.codes.length > 0) {
    filter.id = payloads.codes.map(code => code.value);
  }

  return sanitizeFilter(filter) as Strapi4RequestParams['filters'];
}

function filterByBudget(budgets: FilterDefinition<'budgets'>[]) {
  const budgetFilter = {
    $or: budgets
      .map(budget => {
        if (budget.value.includes('-')) {
          const [min, max] = budget.value.split('-');
          return {
            $and: [
              { price: { $gte: min ? parseInt(min) : undefined } },
              { price: { $lt: max ? parseInt(max) : undefined } }
            ]
          };
        }

        if (budget.value.includes('+')) {
          const min = parseInt(budget.value.replace('+', ''));
          return { price: { $gte: min } };
        }
        return undefined;
      })
      .filter(item => item)
  };

  return budgetFilter;
}

const createCategoryFilterObject = (items?: FilterDefinition<string>[]) => {
  if (items && Array.isArray(items)) {
    return {
      categories: {
        [items[0]?.strategy ?? 'urlKey']: { $containsi: items.map(item => item.value) }
      }
    };
  }
  return { categories: {} };
};

function filterByDuration(durations: FilterDefinition<'durations'>[]) {
  const durationFilter = {
    $or: durations
      .map(duration => {
        if (duration.value.startsWith('-')) {
          const max = parseInt(duration.value.replace('-', ''));
          return {
            days: { $lt: max }
          };
        }

        if (duration.value.includes('-')) {
          const [min, max] = duration.value.split('-');
          return {
            $and: [
              { days: { $gte: min ? parseInt(min) : undefined } },
              { days: { $lt: max ? parseInt(max) : undefined } }
            ]
          };
        }

        if (duration.value.includes('+')) {
          const min = parseInt(duration.value.replace('+', ''));
          return { days: { $gte: min } };
        }
        return undefined;
      })
      .filter(e => e)
  };

  return durationFilter;
}

function getArticleFilter(payloads: FilterObjectPayload) {
  const filter: Strapi4RequestParams['filters'] = {};

  if (payloads.articleTags && payloads.articleTags.length > 0) {
    filter.tags = {
      slug: payloads.articleTags.map(item => item.value)
    };
  }

  if (payloads.wpContinents && payloads.wpContinents.length > 0) {
    filter.geographicZones = {
      slug: payloads.wpContinents.map(item => item.value)
    };
  }

  return filter;
}

function getEventFilter(payloads: FilterObjectPayload) {
  const filter: Strapi4RequestParams['filters'] = {};

  if (payloads.wpContinents && payloads.wpContinents.length > 0) {
    filter.geographicZones = {
      slug: payloads.wpContinents.map(item => item.value)
    };
  }

  if (payloads.eventTags && payloads.eventTags.length > 0) {
    const knownEvents = [
      { value: 'conference' },
      { value: 'forum-atelier' },
      { value: 'signature' },
      { value: 'visioconference' },
      { value: 'concert' },
      { value: 'exposition' },
      { value: 'partenariat' }
    ];

    filter.tags =
      payloads.eventTags[0]?.value === 'all'
        ? {
            slug: {
              $in: payloads.eventTags.filter(item => item.value !== 'all'),
              $notIn: knownEvents.map(knownEvent => knownEvent.value)
            }
          }
        : {
            slug: payloads.eventTags.map(item => item.value)
          };
  }

  if (payloads.months && payloads.months.length > 0) {
    const monthFilters = payloads.months.map(month => {
      const selectedMonth = month.value;

      const startOfMonth = `${selectedMonth}-01`;

      const [year, monthNumber] = selectedMonth.split('-');
      const lastDay = new Date(Number(year), Number(monthNumber), 0).getDate();
      const endOfMonth = `${selectedMonth}-${lastDay}`;

      return {
        $or: [
          {
            startDate: {
              $gte: startOfMonth,
              $lte: endOfMonth
            }
          },
          {
            endDate: {
              $gte: startOfMonth,
              $lte: endOfMonth
            }
          },
          {
            endDate: { $null: true },
            startDate: {
              $gte: startOfMonth,
              $lte: endOfMonth
            }
          }
        ]
      };
    });

    filter.$or = monthFilters;
  }

  filter.sfCampaignStatus = 'active'; // TODO: add it after

  filter.$and = [
    {
      tags: {
        slug: { $notIn: ['catalogues', 'actualite-voyageur'] }
      }
    },
    { privateEvent: { $not: true } }
  ];

  return filter;
}

// Generated by ChatGPT
function sanitizeFilter(filter: Record<string, unknown>): unknown {
  if (Array.isArray(filter)) {
    const cleanedArray = filter
      .map(item => sanitizeFilter(item))
      .filter(item => {
        if (typeof item === 'object' && item !== null) {
          return Object.keys(item).length > 0;
        }
        return item !== undefined;
      });
    return cleanedArray.length > 0 ? cleanedArray : undefined;
  } else if (typeof filter === 'object' && filter !== null) {
    const cleanedObject: Record<string, unknown> = {};
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        const cleanedValue = sanitizeFilter(filter[key] as Record<string, unknown>);
        if (
          typeof cleanedValue === 'object' &&
          cleanedValue !== null &&
          Object.keys(cleanedValue).length > 0
        ) {
          cleanedObject[key] = cleanedValue;
        } else if (cleanedValue !== undefined) {
          cleanedObject[key] = cleanedValue;
        }
      }
    }
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : undefined;
  }
  return filter;
}
